import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { useEffect, useState } from "react";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

export interface TimeBetweenProps {
	from: Date;
	to?: Date;
	updateInterval?: number;
	isMobilePreview?: boolean;
	className?: string;
}

export const TimeBetween: React.FC<TimeBetweenProps> = ({
	from,
	to,
	updateInterval,
	isMobilePreview = false,
	className,
}) => {
	const [time, setTime] = useState(dayjs(from).from(to ?? Date.now()));

	useEffect(() => {
		dayjs.updateLocale("en", {
			relativeTime: {
				future: "in %s",
				past: "%s ago",
				s: isMobilePreview ? "just now" : "a few seconds",
				m: isMobilePreview ? "a min" : "a minute",
				mm: isMobilePreview ? "%dm" : "%d minutes",
				h: "an hour",
				hh: isMobilePreview ? "%dh" : "%d hours",
				d: "a day",
				dd: isMobilePreview ? "%dd" : "%d days",
				M: "a month",
				MM: isMobilePreview ? "%dmon" : "%d months",
				y: "a year",
				yy: "%d years",
			},
		});
	}, [from, isMobilePreview]);

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(dayjs(from).from(to ?? Date.now()));
		}, updateInterval ?? 1000);

		return () => {
			clearInterval(interval);
		};
	}, [from, to, updateInterval]);

	return (
		<time dateTime={new Date(from).toLocaleDateString()} className={className}>
			{time}
		</time>
	);
};
